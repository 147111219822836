// @format

import "jquery";

import { Turbo, cable } from "@hotwired/turbo-rails";
import Chartkick from "chartkick";
import Chart from "chart.js";
Chart.defaults.global.defaultFontColor = "#ccc";
import RailsUJS from "@rails/ujs";

RailsUJS.start();
Turbo.start();

window["Chartkick"] = Chartkick;
window["Turbo"] = Turbo;

import { initHeader } from "../js/header";
import { initSubmitOnChange } from "../js/submit_on_change";
import { initChoices } from "../js/choices";
import {
	initEmailResponses,
	initFullThreadEmailResponses,
} from "../js/email_responses";
import { initCRMHiddenListener } from "../js/crm";
import { initContacted } from "../js/contacted";
import { initLoginForm } from "../js/log_in";
import { initNotes } from "../js/notes";
import { initLitepicker } from "../js/litepicker";
import {
	initProspectToggle,
	initEditProspectListener,
	initPhoneCompletedListener,
	initEmailsReceived,
	initRejectReasonListener,
	initReApproachedBtn,
	initCheckedBotsProspect,
	initBotProspectHistory,
} from "../js/prospect";
import { initTooltips } from "../js/tooltip";
import { initTeamtailor } from "../js/teamtailor";
import { initAirtable } from "../js/airtable";
import { initDatePicker } from "../js/date_picker";
import { initScheduleDate } from "../js/schedule_date";
import { initQlfrModal } from "../js/qlfr_modal";
import { initSearchPreviews } from "../js/search_previews";
import { initLanguagePicker } from "../js/language_picker";
import { initFront } from "../js/front";
import { initSelectpicker, destroySelectpicker } from "../js/selectpicker";
import { initCustomSelect } from "../js/custom_select";
import { initDestroySequenceListener, initSequence } from "../js/sequences";
import { initDestroyBotListener, initShowSequences } from "../js/bots";
import { initDisconnectMailListener } from "../js/users";
import {
	initSummernote,
	destroySummernote,
	initOptOutSetter,
	checkOptOutPresence,
} from "../js/summernote";
import { initCommentSidebar } from "../js/comment_sidebar";
import { initHomeBanner } from "../js/home_banner";
import { initAutosize } from "../js/textarea";
import { initTimeZone } from "../js/time_zone";
import { initOverview } from "../js/overview";
import { initUserCompanies } from "../js/user_companies";
import { initCsvSelectsListener } from "../js/csv_data_import";
import { initCreditBatches } from "../js/credit_batches";
import { initClipCopy } from "../js/clip_copy";
import { initNotifier } from "../js/notifier";
import { initFileUpload } from "../js/file_upload";
import { initHubspot } from "../js/hubspot";

// css
import "choices.js/assets/styles/css/choices.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "summernote/dist/summernote-bs4.css";
import "../application.scss";

$(document).on("turbo:load", function (event) {
	initSearchPreviews();
});

$(document).on("turbo:load turbo:render", function (event) {
	window.scrollTo(0, 0); // force scroll to top at all times
	initLitepicker();
	initAutosize();
	initHeader();
	initChoices();
	initTooltips();
	initHubspot();
	initCommentSidebar();
	initCRMHiddenListener();
	initLoginForm();
	initEmailResponses();
	initCheckedBotsProspect();
	initOverview();
	initTimeZone();
	initNotes();
	initContacted();
	initTeamtailor();
	initAirtable();
	initSelectpicker();
	initCustomSelect();
	initSequence();
	initSummernote();
	initClipCopy();
	initUserCompanies();
	initCsvSelectsListener();
	initCreditBatches();
	initNotifier();
	initFileUpload();
	initDatePicker();
	initScheduleDate();
	initQlfrModal();
	//initOptOutSetter();
	checkOptOutPresence();
	initFront();
});

initHomeBanner();
initProspectToggle();
initLanguagePicker();
initEditProspectListener();
initPhoneCompletedListener();
initDestroyBotListener();
initDisconnectMailListener();
initShowSequences();
initDestroySequenceListener();
initEmailsReceived();
initReApproachedBtn();
initRejectReasonListener();
initFullThreadEmailResponses();
initSubmitOnChange();
initBotProspectHistory();

$(document).on("render_async_load", () => {
	initSelectpicker();
	initClipCopy();
	// initFullThreadEmailResponses();

	const async_chart = $("#async-chart");

	if (async_chart.length) {
		const data = async_chart.data("chart-data");
		const options = async_chart.data("chart-options");
		new Chartkick.ColumnChart("async-chart", data, options);

		initLitepicker();
	}

	initSelectpicker();
	initClipCopy();
	//initFullThreadEmailResponses();
	initSubmitOnChange();
});

$(document).on("turbo:before-cache", () => {
	destroySummernote();
	destroySelectpicker();

	$("script[nonce]").each(function (index, element) {
		$(element).attr("nonce", element.nonce);
	});
});
