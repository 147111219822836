// @format

import { Turbo } from '@hotwired/turbo-rails';


const formIds: string[] = [
  'sequence_subject',
  'sequence_body',
  'sequence_follow_up_1_body',
  'sequence_follow_up_2_body',
  'sequence_follow_up_3_body',
  'sequence_follow_up_4_body',
  'sequence_follow_up_5_body',
];

var initialHashes: number[] = [];

$(document).on('turbo:before-visit', e => {
  if ($('#sequence_subject').length > 0 && sequenceHasBeenEdited()) {
    if (
      !confirm(
        'Your changes will be lost. Click OK to continue without saving.',
      )
    ) {
      e.preventDefault();
      return false;
    }
  }
});

export function initDestroySequenceListener(): void {
  $(document).on('click', '.destroy-sequence', e => {
    const t = $(e.currentTarget);
    if (confirm('Are you sure you want to delete this sequence?')) {
      t.html('Deleting ...');
      t.prop('disabled', true);
      fetch(
        '/bots/' + t.data('bot-id') + '/sequences/' + t.data('id') + '/delete',
        {
          method: 'delete',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-TOKEN': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      ).then(_ => Turbo.visit('/bots/' + t.data('bot-id') + '/sequences'));
    }
  });
}

export function initSequence(): void {
  if ($('#sequence_subject').length > 0) {
    initialHashes = currentSequenceHashes();
  }
  $(document).on('click', '.preview-hide', e => {
    const t = $(e.currentTarget);
    const sequencePartContainer = t.closest('.sequence-part');
    const previewContainer = sequencePartContainer.find('.preview-container');
    const link = $('.preview-hide');
    const editor = sequencePartContainer.find('.editor');
    editor.show();
    previewContainer.removeClass('preview-showing');
    link.removeClass('preview-hide');
    link.addClass('preview-link');
    link.text('Preview');
  });

  $(document).on('click', '.preview-link', e => {
    const t = $(e.currentTarget);
    const sequencePartContainer = t.closest('.sequence-part');
    const preview = sequencePartContainer.find('.preview');
    const previewContainer = sequencePartContainer.find('.preview-container');
    const editor = sequencePartContainer.find('.editor');
    let sequence_id = t.data('sequence-id');
    let bot_id = t.data('bot-id');
    const link = $('.preview-link');
    let html = sequencePartContainer.find('.note-editable').html();
    getPreviewContent(sequence_id, bot_id, html, preview, previewContainer, link, editor);
  });

  $('.add-follow-up-link').click(e => {
    const t = $(e.currentTarget);
    t.hide();
    $('.follow-up-' + t.data('n')).removeClass('sequence-part-hidden');
  });
}

function getPreviewContent(
  sequence_id: string,
  bot_id: string,
  html: string,
  previewElement: JQuery<HTMLElement>,
  previewContainer: JQuery<HTMLElement>,
  link: JQuery<HTMLElement>,
  editor: JQuery<HTMLElement>,
) {
  var data = new FormData();
  data.append('authenticity_token', $('[name="csrf-token"]').attr('content'));
  data.append('html', html);
  $.ajax({
    url: '/bots/' + bot_id + '/sequences/' + sequence_id + '/preview',
    cache: false,
    contentType: false,
    processData: false,
    data: data,
    type: 'PUT',
    success: function(html) {
      previewElement.html(html);
      previewContainer.addClass('preview-showing');
      link.removeClass('preview-link');
      link.addClass('preview-hide');
      editor.hide();
      link.text('View editor');
    },
  });
}

function sequenceHasBeenEdited(): boolean {
  return (
    JSON.stringify(initialHashes) !== JSON.stringify(currentSequenceHashes())
  );
}

function currentSequenceHashes(): number[] {
  return formIds.map(field => {
    return hashCode(
      $('#' + field).length == 0
        ? ''
        : $('#' + field)
            .val()
            .toString(),
    );
  });
}

function hashCode(str: string): number {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    var character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
