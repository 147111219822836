export function initNotes(): void {
  $(document).on('click', '.notes-button', e => {
    const botsProspectId = $(e.currentTarget).data('bots-prospect-id');
    $('.notes-' + botsProspectId).toggle('fast');
  });

  $(document).on("ajax:success", ".write-note-form", event => {
    const botsProspectId = event.target.id.replace("edit_bots_prospect_", "")
    $(".notes-" + botsProspectId).hide()
    $(".notes-button-" + botsProspectId).text("Notes")
  })
}
